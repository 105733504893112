import React from "react"

import birthInDueSeason from "./birth-in-due-season.jpg"
import kingdomPowerElectricalServices from "./kingdom-power-electrical-services.jpg"
import seasonOfHope from "./season-of-hope.jpg"
import turnTimer from "./turn-timer.jpg"
import countdowns from "./countdowns.jpg"
import ferreiraLife from "./ferreira-life.jpg"

const works: [image: string, title: string, url: string][] = [
	[birthInDueSeason, "Birth In Due Season", "https://birthindueseason.org"],
	[
		kingdomPowerElectricalServices,
		"Kingdom Power Electrical Services",
		"https://kpes.pro"
	],
	[seasonOfHope, "Season of Hope", "https://season4hope.org"],
	[ferreiraLife, "Ferreira.Life", "https://ferreira.life"],
	[turnTimer, "Turn Timer", "https://timer.ericmakesapps.com"],
	[countdowns, "Countdowns", "https://countdowns.ericmakesapps.com"]
]

export const Work: React.FC = () => {
	return (
		<div className="w-content">
			<h2 className="text-3xl mb-5">Our work</h2>
			<ul className="grid grid-cols-2 lg:grid-cols-4 gap-4">
				{works.map(([image, title, url]) => (
					<li key={url} className="flex items-center justify-center">
						<a
							href={url}
							className="text-xl block w-full text-center"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img
								src={image}
								alt={`Screenshot of ${title}`}
								className="w-full max-h-96 object-contain"
							/>
							{title}
						</a>
					</li>
				))}
			</ul>
		</div>
	)
}
