import { join } from "@ericbf/helpers/join"
import React from "react"

import { MdCheck } from "react-icons/md"

const services = [
	"Custom websites",
	"Templated websites",
	"Native apps",
	"Embedded systems apps",
	"Servers",
	"Databases",
	"Website updates",
	"Data processing",
	"Consultations",
	"Web design",
	"Macros",
	"Workflows",
	"Scripts",
	"Network setup",
	"Hardware installs",
	"More..."
]

export const Services: React.FC = () => {
	return (
		<div className="w-content">
			<h2 className="text-3xl mb-5">Services we provide</h2>
			From completely custom websites (react, angular, vue, etc.) to native mobile apps
			(iOS, Android, Apple Watch, Apple TV, etc.), from wordpress (or wix, webflow, etc.)
			to resurrecting old or abandoned projects. Need an Excel macro? A shell script? An
			Automator workflow? Eric makes apps has the expertise to get the job done. Need a
			custom database? A specialized server or daemon? No problem is too hard, too
			obscure, or too small—whatever the solution calls for is within the realm of
			possibility.
			<ul className="grid gap-1 mt-3">
				{services.map((service, i, arr) => (
					<li
						key={i}
						className={join(
							"flex items-center",
							i < Math.ceil(arr.length / 2) ? "col-start-1" : "col-start-2"
						)}
						style={{
							gridRow:
								i < Math.ceil(arr.length / 2) ? i + 1 : i - Math.ceil(arr.length / 2) + 1
						}}
					>
						<MdCheck className="mr-2" /> {service}
					</li>
				))}
			</ul>
		</div>
	)
}
