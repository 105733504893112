import { join } from "@ericbf/helpers/join"
import React from "react"

import {
	MdArrowBack,
	MdArrowDownward,
	MdArrowForward,
	MdArrowUpward,
	MdBuild,
	MdDraw,
	MdNewReleases,
	MdRepeat
} from "react-icons/md"

import hero from "./hero.jpg"

const steps: [typeof MdBuild, string][] = [
	[MdDraw, "Plan"],
	[MdArrowForward, ""],
	[MdBuild, "Build"],
	[MdArrowUpward, ""],
	[() => <></>, ""],
	[MdArrowDownward, ""],
	[MdRepeat, "Iterate"],
	[MdArrowBack, ""],
	[MdNewReleases, "Release"]
]

export const Home: React.FC = () => {
	return (
		<>
			<div
				className="h-72 md:h-80 lg:h-96 w-full flex items-center justify-center sm:bg-fixed bg-top bg-cover"
				style={{
					backgroundImage: `url(${hero})`
				}}
			>
				<span
					className="text-white text-2xl sm:text-3xl md:text-4xl px-9 text-center font-['Just_Realize']"
					style={{ textShadow: "0 0 7px rgba(0, 0, 0, 0.5)" }}
				>
					Let’s make something beautiful
				</span>
			</div>
			<div className="w-content mt-6">
				Turning your vision into reality utilizing the latest, tried and true
				methodologies. Eric makes apps is certified and experienced in Agile, scrum, SAFe,
				and other methodologies. Using these in a highly efficient, iterative process, we
				get your projects to the glass quicker and more completely than you would think
				possible.
				<ul className="grid gap-3 mt-4 grid-cols-[1fr_auto_1fr] grid-rows-[1fr_auto_1fr] max-w-2xl mx-auto">
					{steps.map(([Icon, title], i) => (
						<li
							key={i}
							className={join(
								"flex flex-col items-center justify-center",
								title && "p-4"
							)}
						>
							<Icon className={join("text-5xl", title && "mb-2")} /> {title}
						</li>
					))}
				</ul>
			</div>
		</>
	)
}
