import { forwardRef, useEffect, useState } from "react"
import Reaptcha, { Props } from "reaptcha"

export type Recaptcha = Reaptcha

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Recaptcha = forwardRef<
	Reaptcha,
	Props & { onShown?(): void; onHidden?(): void }
>(({ onHidden, onShown, ...props }, ref) => {
	const [parent, setParent] = useState<HTMLDivElement>()

	useEffect(() => {
		const findParent = () => {
			return Array.from(document.body.children).find((el) => {
				if (el.tagName !== "DIV") {
					return false
				}

				const div = el as HTMLDivElement

				if (div.style.visibility !== "hidden") {
					return false
				}

				if (!div.querySelectorAll("iframe[title*='challenge']").length) {
					return false
				}

				return true
			}) as HTMLDivElement
		}

		const parent = findParent()

		if (!parent) {
			const observer = new MutationObserver(() => {
				const parent = findParent()

				if (parent) {
					setParent(parent as HTMLDivElement)
					observer.disconnect()
				}
			})

			observer.observe(document.body, {
				childList: true
			})

			return () => observer.disconnect()
		} else {
			setParent(parent)
		}
	}, [])

	useEffect(() => {
		if (parent) {
			const container = parent

			let visible = false

			const observer = new MutationObserver(() => {
				const style = getComputedStyle(container)

				if (!visible && style.visibility === "visible") {
					visible = true
					onShown?.()
				} else if (visible && style.visibility === "hidden") {
					visible = false
					onHidden?.()
				}
			})

			observer.observe(container, {
				attributes: true,
				attributeFilter: ["style"]
			})

			// console.log("Recaptcha modal observer started.")

			return () => {
				observer.disconnect()
			}
		}
	}, [onHidden, onShown, parent])

	return <Reaptcha ref={ref} {...props} />
})
