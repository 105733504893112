import React from "react"

import profile from "./profile.jpeg"

export const About: React.FC = () => {
	return (
		<div className="w-content">
			<h2 className="text-3xl mb-5">About Eric makes apps</h2>
			<img
				src={profile}
				alt="Headshot of Eric Ferreira, founder of Eric makes apps"
				className="h-[7em] float-left mr-4 rounded-xl"
			/>
			Eric Ferreira founded Eric makes apps in January of 2022, but has been in the
			software industry since 2014. He started his career working for AT&amp;T as an
			applications developer for one of their cutting edge, R&D teams (CDI), specializing
			in UX (user experience) and HCD (human centered design). Over time, Eric moved up to
			his final role with AT&amp;T as a Principle Software Engineer, leading a team of
			engineers on AT&amp;T’s flagship design team, Design Technology.
		</div>
	)
}
