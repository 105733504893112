import React, { useMemo } from "react"

import { tuple } from "@ericbf/helpers/tuple"
import { join } from "@ericbf/helpers/join"

import { Header } from "./components/header"
import { Footer } from "./components/footer"

import { Home } from "./pages/home"

import { Services } from "./pages/services"
import { About } from "./pages/about"
import { Work } from "./pages/work"
import { Contact } from "./pages/contact"

const paths: [title: string, path: string, component: React.FC][] = [
	["Services", "services", Services],
	["About", "about", About],
	["Work", "work", Work],
	["Contact", "contact", Contact]
]

function App() {
	const pages = useMemo(() => paths.map(([title, path]) => tuple(title, path)), [])

	return (
		<div className="text-black dark:text-white flex flex-col min-h-full">
			<Header pages={pages} />
			<main
				className={join(
					"w-full flex-grow flex flex-col items-center bg-background-gray/95 dark:bg-black",
					pages.length % 2 === 0 && "pb-8"
				)}
			>
				<Home />
				{paths.map(([, path, Page], i) => {
					if (i % 2 === 0) {
						return (
							<React.Fragment key={path}>
								<div data-anchor={path} className="relative -top-2 md:-top-8" />
								<div
									key={path}
									className="my-8 last:mb-px py-8 bg-[color:var(--nav)] w-full text-white"
								>
									<Page />
								</div>
							</React.Fragment>
						)
					}

					return (
						<React.Fragment key={path}>
							<div data-anchor={path} className="relative -top-[4.5rem] md:-top-24" />
							<Page key={path} />
						</React.Fragment>
					)
				})}
			</main>
			<Footer />
		</div>
	)
}

export default App
