import React from "react"

export const Footer: React.FC = () => {
	return (
		<footer className="bg-[color:var(--nav)] flex justify-center text-white">
			<div className="w-content h-full flex items-center justify-center text-sm py-4">
				<div className="text-right">© {new Date().getFullYear()}, Eric makes apps</div>
			</div>
		</footer>
	)
}
