import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react"
import {
	MdOutlineCheckCircle,
	MdOutlineErrorOutline,
	MdOutlineFacebook,
	MdOutlineMail,
	MdOutlinePhonelinkRing
} from "react-icons/md"
import emailjs from "@emailjs/browser"

import { join } from "@ericbf/helpers/join"

import { Recaptcha } from "../../components/recaptcha"

import "./Contact.scss"

const emailPattern =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const Contact: React.FC = () => {
	const methods: [
		icon: typeof MdOutlineMail,
		title: string,
		url: string,
		text: string
	][] = [
		[
			MdOutlineMail,
			"Email us",
			"mailto:hello@ericmakesapps.com",
			"hello@ericmakesapps.com"
		],
		[MdOutlinePhonelinkRing, "Call or text us", "tel:386-287-2777", "(386) 287-APPS"],
		[
			MdOutlineFacebook,
			"Connect on Facebook",
			"https://www.facebook.com/ericmakesapps",
			"facebook.com/ericmakesapps"
		]
	]

	const recaptcha = useRef<Recaptcha>(null)

	const [validating, setValidating] = useState(false)
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [message, setMessage] = useState("")
	const [success, setSuccess] = useState<boolean>()

	const successResetTimeout = useRef(0)

	useEffect(() => {
		if (success !== undefined) {
			clearTimeout(successResetTimeout.current)

			successResetTimeout.current = window.setTimeout(() => {
				setSuccess(undefined)
			}, 5000)
		}
	}, [success])

	const updaterFor = useCallback(
		(setter: React.Dispatch<string>) => (event: { target: { value: string } }) => {
			setter(event.target.value)
		},
		[]
	)

	const sendEmail = useCallback(
		async (token: string) => {
			try {
				if (!token) {
					throw new Error("Recaptcha returned an undefined token")
				}

				if (!name || !email.match(emailPattern) || !message) {
					throw new Error("Form not valid")
				}

				const result = await emailjs.send(
					"service_cp3hfph",
					"template_57gkcnq",
					{
						name,
						email,
						message,
						"g-recaptcha-response": token
					},
					"fYjEZcBfcTbTI_tEH"
				)

				if (result.status !== 200) {
					throw new Error(result.text)
				}

				setName("")
				setEmail("")
				setMessage("")
				setSuccess(true)
			} catch (error) {
				console.log(error)
				setSuccess(false)
			}

			setLoading(false)
		},
		[email, message, name]
	)

	const verifyRecaptcha = useCallback(
		async (event: FormEvent) => {
			event.preventDefault()

			try {
				setValidating(false)

				if (!name || !email.match(emailPattern) || !message) {
					throw new Error("Form not valid")
				}

				setLoading(true)

				if (!recaptcha.current) {
					throw new Error("Recaptcha not available")
				}

				const token = await recaptcha.current.getResponse()

				if (token) {
					sendEmail(token)
				} else {
					await recaptcha.current.execute()
				}
			} catch (error) {
				console.log(error)
				setSuccess(false)
				setLoading(false)
			}
		},
		[email, message, name, sendEmail]
	)

	const onHidden = useCallback(() => setLoading(false), [])

	const validate = useCallback(() => setValidating(true), [])

	const verifyFailed = useCallback(() => {
		console.log("Recaptcha verification failed")

		setLoading((loading) => {
			if (loading) {
				setSuccess(false)
			}

			return false
		})
	}, [])

	return (
		<div className="w-content md:py-10 text-lg grid grid-cols-1 md:grid-cols-2">
			<div className="flex flex-col items-start">
				<h2 className="text-3xl mb-0">Contact Us</h2>
				<h3 className="text-xl mb-8">Let’s get to work!</h3>
				{methods.map(([Icon, title, url, text]) => (
					<a href={url} key={title} className="flex flex-col mb-6 last:mb-0">
						<span className="flex items-center gap-2 text-xl">
							<Icon className="inline text-2xl" /> {title}
						</span>
						<span className="text-base">{text}</span>
					</a>
				))}
			</div>
			<form className="mt-10 md:mt-0 flex flex-col gap-4" onSubmit={verifyRecaptcha}>
				<h3 className="text-2xl mb-2">Send us a message</h3>
				<label className="cursor-pointer flex flex-col max-w-xs">
					<span className="label-text text-[color:inherit] mb-2">What is your name?</span>
					<input
						type="text"
						placeholder="Name"
						name="name"
						autoComplete="name"
						value={name}
						onChange={updaterFor(setName)}
						className={join(
							"input w-full max-w-xs text-black",
							validating && "invalid:input-error"
						)}
						required
						disabled={loading}
					/>
				</label>
				<label className="cursor-pointer flex flex-col max-w-xs">
					<span className="label-text text-[color:inherit] mb-2">
						What is your email?
					</span>
					<input
						type="email"
						placeholder="Email"
						name="email"
						autoComplete="email"
						value={email}
						onChange={updaterFor(setEmail)}
						className={join(
							"input w-full max-w-xs text-black",
							validating && "invalid:input-error"
						)}
						required
						pattern={emailPattern.source}
						disabled={loading}
					/>
				</label>
				<label className="cursor-pointer flex flex-col">
					<span className="label-text text-[color:inherit] mb-2">How can we help?</span>
					<textarea
						className={join(
							"textarea resize-none text-black",
							validating && "invalid:input-error"
						)}
						placeholder="Message"
						value={message}
						onChange={updaterFor(setMessage)}
						required
						disabled={loading}
					></textarea>
				</label>
				<button
					className={join("btn btn-secondary mt-1", loading && "loading")}
					type="submit"
					onClick={validate}
				>
					Send
				</button>
				<span className="label-text-alt text-[color:inherit] -mt-3">
					This site is protected by reCAPTCHA and the Google{" "}
					<a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
					<a href="https://policies.google.com/terms">Terms of Service</a> apply.
				</span>
				<Recaptcha
					ref={recaptcha}
					sitekey="6LduUU4hAAAAANiScE4CHTWHae_kBVsiD16ckiN2"
					size="invisible"
					onVerify={sendEmail}
					onError={verifyFailed}
					onHidden={onHidden}
				/>
				<div
					className={join("alert alert-success shadow-lg", success !== true && "hidden")}
				>
					<div>
						<MdOutlineCheckCircle className="text-4xl" />
						<span>Your message was sent! We’ll be in touch soon!</span>
					</div>
				</div>
				<div
					className={join("alert alert-error shadow-lg", success !== false && "hidden")}
				>
					<div>
						<MdOutlineErrorOutline className="text-4xl" />
						<span>
							Message failed to send! Try again, or try one of the other contact methods.
						</span>
					</div>
				</div>
			</form>
		</div>
	)
}
