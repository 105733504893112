import React, { useCallback } from "react"

export type ScrollButtonProps = {
	path: string
} & React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>

export const ScrollButton: React.FC<ScrollButtonProps> = ({
	path,
	onClick,
	...props
}) => {
	const replaceState = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			onClick?.(e)

			document.querySelector(`[data-anchor="${path}"`)?.scrollIntoView()
		},
		[onClick, path]
	)

	return <button onClick={replaceState} {...props} />
}
