import React from "react"
import { ScrollButton } from "../ScrollButton"

import logoWithText from "./logo-text.svg"
import logo from "./logo.svg"

export type Page = [name: string, link: string]

export const Header: React.FC<{ pages: Page[] }> = ({ pages }) => {
	return (
		<header className="bg-[color:var(--nav)] sticky top-0 flex justify-center py-2 md:py-3 text-white text-sm md:text-base z-10 border-b border-background-gray/10 dark:border-black/30">
			<nav className="ml-5 md:ml-0 w-[calc(100%-1.25rem)] md:w-content flex items-stretch justify-start md:justify-between">
				<ScrollButton
					path="home"
					className="flex items-center h-10 md:h-14 flex-shrink-0 mr-2"
				>
					<img src={logoWithText} alt="logo" className="h-full hidden md:block" />
					<img src={logo} alt="logo" className="h-full md:hidden" />
				</ScrollButton>
				<div className="grid grid-flow-col gap-2 relative">
					{pages.map(([name, link]) => (
						<ScrollButton key={name} path={link} className="flex items-center p-2 md:p-3">
							{name}
						</ScrollButton>
					))}
				</div>
			</nav>
		</header>
	)
}
